import { createClient } from "@supabase/supabase-js";

let cleanSupabaseUrl = (process.env.NEXT_PUBLIC_SUPABASE_URL || "").replace(
  /\x1B\[\d+m/g,
  "",
);
let cleanSupabasePublicKey = (
  process.env.NEXT_PUBLIC_SUPABASE_PUBLIC_KEY || ""
).replace(/\x1B\[\d+m/g, "");

var supabase;

try {
  supabase = createClient(cleanSupabaseUrl, cleanSupabasePublicKey, {
    realtime: true,
    autoRefreshToken: true,
  });
} catch (error) {}

export default supabase;
