import { useState, useEffect } from "react";
import Link from "next/link";

const navigation = {
  support: [
    {
      name: "User Guide",
      href: "https://rulebricks.com/docs",
    },
    { name: "Pricing", href: "/pricing" },
  ],
  company: [
    { name: "About", href: "/about" },
    { name: "Blog", href: "/blog" },
  ],
  legal: [
    { name: "Privacy", href: "/legal/privacy-policy" },
    { name: "Terms", href: "/legal/terms-of-service" },
  ],
  social: [
    {
      name: "Twitter",
      href: "https://twitter.com/rulebricks",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      ),
    },
    {
      name: "GitHub",
      href: "https://github.com/rulebricks",
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/prefix-software",
      icon: (props) => (
        <svg
          fill="currentColor"
          className="rounded-full"
          viewBox="0 0 35 35"
          {...props}
        >
          <path
            fillRule="evenodd"
            d="M3 0H32C33.6569 0 35 1.34315 35 3V32C35 33.6569 33.6569 35 32 35H3C1.34315 35 0 33.6569 0 32V3C0 1.34315 1.34315 0 3 0ZM11.1589 8.25206C11.1623 6.64103 9.84022 5.31701 8.23718 5.3157C6.61471 5.31447 5.28746 6.62928 5.28746 8.24092C5.28715 9.90264 6.65779 11.2053 8.21744 11.1737C9.75512 11.2104 11.155 9.93896 11.1589 8.25206ZM9.94028 29.6808H6.50572C6.05997 29.6808 5.69863 29.3195 5.69863 28.8737V14.2217C5.69863 13.776 6.05997 13.4146 6.50572 13.4146H9.94028C10.386 13.4146 10.7474 13.776 10.7474 14.2217V28.8738C10.7473 29.3195 10.386 29.6808 9.94028 29.6808ZM24.1508 12.9994C27.2225 12.9994 29.7126 15.4895 29.7126 18.5612H29.7125V29.3494C29.7125 29.5344 29.5624 29.6845 29.3773 29.6844L24.988 29.6834C24.8031 29.6833 24.6531 29.5333 24.6531 29.3483V20.3007C24.6531 19.5185 24.3362 18.8101 23.8235 18.2978C23.3041 17.778 22.5833 17.4595 21.7883 17.4684C20.2308 17.486 18.9886 18.7793 18.9886 20.3368V29.3471C18.9886 29.5322 18.8385 29.6823 18.6535 29.6822L14.275 29.6811C14.09 29.6811 13.9401 29.5311 13.9401 29.3461V13.7498C13.9401 13.5647 14.0902 13.4147 14.2752 13.4147H18.5602C18.7453 13.4147 18.8953 13.5648 18.8953 13.7498V15.5483C18.8957 15.5475 18.9127 15.5498 18.9413 15.5541C19.0241 15.4043 19.1143 15.2592 19.2116 15.1195C20.1047 13.8379 21.5894 12.9994 23.2699 12.9994H24.1508Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: "Status",
      href: "https://status.rulebricks.com",
      icon: (props) => (
        <div className="self-center h-full w-[200px] align-middle inline-flex opacity-75 hover:opacity-100 -mt-px">
          <iframe
            className="self-center"
            src="https://status.rulebricks.com/badge?theme=dark"
            width="200"
            height="30"
            frameBorder="0"
            scrolling="no"
          ></iframe>
        </div>
      ),
    },
  ],
};

export default function Footer() {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    mounted && (
      <footer className="bg-black" aria-labelledby="footer-heading">
        <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="md:flex md:justify-between md:gap-8">
            <div className="space-y-4 xl:col-span-1">
              <Link legacyBehavior href="/">
                <a>
                  <div className="inline-flex cursor-pointer mt-1.5 align-middle self-center -mr-24">
                    <img
                      className="w-8 h-full self-center"
                      src="/static/images/logo-white.png"
                      alt="Rulebricks Logo"
                    />
                    <span className="ml-4 text-2xl font-semibold text-white">
                      Rulebricks
                    </span>
                  </div>
                </a>
              </Link>
              <p className="text-lg text-white">
                The rule engine for modern businesses.
              </p>
              <div className="flex space-x-6">
                {navigation.social.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="text-neutral-400 hover:text-white"
                    target="_blank"
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
            <div className="mt-12 md:col-span-2 md:mt-0">
              <div className="grid grid-cols-2 md:grid md:grid-cols-3 md:gap-8 md:space-x-8 max-w-2xl">
                <div className="md:mt-0">
                  <h3 className="text-lg font-semibold text-white">Support</h3>
                  <ul role="list" className="mt-2 space-y-2">
                    {navigation.support.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-lg text-neutral-400 hover:text-white hover:underline underline-offset-2"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="md:mt-0">
                  <h3 className="text-lg font-semibold text-white">Company</h3>
                  <ul role="list" className="mt-2 space-y-2">
                    {navigation.company.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-lg text-neutral-400 hover:text-white hover:underline underline-offset-2"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-8 md:mt-0">
                  <h3 className="text-lg font-semibold text-white">Legal</h3>
                  <ul role="list" className="mt-2 space-y-2">
                    {navigation.legal.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className="text-lg text-neutral-400 hover:text-white hover:underline underline-offset-2"
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-neutral-800 pt-8 w-full justify-between md:inline-flex">
            <p className="text-lg text-neutral-500 xl:text-center">
              &copy; 2025 Prefix Software, Inc. All rights reserved.
            </p>
            <p className="text-lg text-neutral-500 sm:text-center mt-4 md:mt-0 md:text-right">
              2261 Market Street STE 4695
              <br />
              San Francisco, CA 94114
            </p>
          </div>
        </div>
      </footer>
    )
  );
}
